<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.28462 9.03052L4.99171 12.0855C4.88409 12.3398 4.59068 12.4587 4.33638 12.3511C4.08207 12.2435 3.96316 11.9501 4.07079 11.6958L7.36388 3.91454C7.60517 3.3444 8.41343 3.34515 8.65367 3.91573L11.9298 11.6966C12.037 11.9511 11.9176 12.2443 11.6631 12.3515C11.4086 12.4586 11.1154 12.3392 11.0082 12.0847L9.72225 9.03052L6.28462 9.03052ZM9.3012 8.03052L6.70783 8.03052L8.00782 4.95877L9.3012 8.03052Z"
    fill="#F9F9F9" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1.21799 2.09202C1 2.51984 1 3.0799 1 4.2L1 11.8C1 12.9201 1 13.4802 1.21799 13.908C1.40973 14.2843 1.71569 14.5903 2.09202 14.782C2.51984 15 3.0799 15 4.2 15L11.8 15C12.9201 15 13.4802 15 13.908 14.782C14.2843 14.5903 14.5903 14.2843 14.782 13.908C15 13.4802 15 12.9201 15 11.8L15 4.2C15 3.0799 15 2.51984 14.782 2.09202C14.5903 1.71569 14.2843 1.40973 13.908 1.21799C13.4802 1 12.9201 1 11.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202ZM2.10899 2.54601C2 2.75992 2 3.03995 2 3.6L2 12.4C2 12.9601 2 13.2401 2.10899 13.454C2.20487 13.6422 2.35785 13.7951 2.54601 13.891C2.75992 14 3.03995 14 3.6 14L12.4 14C12.9601 14 13.2401 14 13.454 13.891C13.6422 13.7951 13.7951 13.6422 13.891 13.454C14 13.2401 14 12.9601 14 12.4L14 3.6C14 3.03995 14 2.75992 13.891 2.54601C13.7951 2.35785 13.6422 2.20487 13.454 2.10899C13.2401 2 12.9601 2 12.4 2L3.6 2C3.03995 2 2.75992 2 2.54601 2.10899C2.35785 2.20487 2.20487 2.35785 2.10899 2.54601Z"
    fill="#F9F9F9" />
</template>
